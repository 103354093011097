import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Icon from 'components/ui-components-v2/Icon';
import Translation from 'components/data/Translation';
import { MediaAsset } from 'components/media-management/types/media-management.type';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import User from 'components/data/User';

interface Props {
    asset: MediaAsset;
    onClose: () => void;
}

const MediaManagementAssetMenuItemsCopyApiUrl = ({ asset, onClose }: Props) => {
    if (User.get('accountId') !== '26') return;

    return (
        <MenuItem
            onClick={(e) => {
                e.stopPropagation();

                if (asset.data.files?.[0].url) {
                    navigator.clipboard.writeText(asset.data.files?.[0].url);
                    SnackbarUtils.info(Translation.get('actions.copiedApiUrl', 'media-management'));
                }

                onClose();
            }}>
            <ListItemIcon>
                <Icon fontSize="small">content_copy</Icon>
            </ListItemIcon>
            <ListItemText>{Translation.get('actions.copyApiUrl', 'media-management')}</ListItemText>
        </MenuItem>
    );
};

export default MediaManagementAssetMenuItemsCopyApiUrl;
