import React, { useEffect, useState } from 'react';
import AssetManagementAssetMenuItemsSetAvailable from 'components/asset-management/components/asset-menu-items/set-available';
import AssetManagementAssetMenuItemsSetToDraft from 'components/asset-management/components/asset-menu-items/set-to-draft';
import AssetManagementAssetMenuItemsRestoreToDraft from 'components/asset-management/components/asset-menu-items/restore-to-draft';
import AssetManagementAssetMenuItemsMoveToBin from 'components/asset-management/components/asset-menu-items/move-to-bin';
import AssetManagementAssetMenuItemsDeletePermanently from 'components/asset-management/components/asset-menu-items/delete-permanently';
import AssetManagementMenuItemsWrapper, { AssetManagerMenuButtonType } from 'components/asset-management/components/menu-items-wrapper';
import AssetManagementAssetMenuItemsRemoveFromCollection from 'components/asset-management/components/asset-menu-items/remove-from-collection';
import AssetManagementRemoveAssetDialog from 'components/asset-management/components/dialogs/remove-asset-dialog';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import StopPropagationWrapper from 'components/asset-management/components/stop-propagation-wrapper';
import AssetManagementSetToDraftDialog from 'components/asset-management/components/dialogs/set-to-draft-dialog';
import { MediaAsset } from 'components/media-management/types/media-management.type';
import MediaManagementAssetMenuItemsDownload from '../asset-menu-items/download';
import MediaManagementAssetMenuItemsCopyApiUrl from '../asset-menu-items/api-url-copy';

interface Props {
    asset: MediaAsset;
    buttonType?: AssetManagerMenuButtonType;
    onSetKeepMenuMounted?: (open: boolean) => void; // Used in case we want a callback to the parent component when the menu or dialogs are opened or closed.
}

const MediaManagementAssetMenu = ({ asset, buttonType, onSetKeepMenuMounted }: Props) => {
    const { userHasRight } = useAssetManagementConfigContext();
    const [removeDialogOpen, setRemoveDialogOpen] = useState<boolean>(false);
    const [toDraftDialogOpen, setToDraftDialogOpen] = useState<boolean>(false);
    const [permanent, setPermanent] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();

    useEffect(() => {
        if (Boolean(anchorEl) || removeDialogOpen || toDraftDialogOpen) {
            if (onSetKeepMenuMounted) onSetKeepMenuMounted(true);
        } else {
            if (onSetKeepMenuMounted) onSetKeepMenuMounted(false);
        }
    }, [anchorEl, removeDialogOpen, toDraftDialogOpen]);

    const onSetAssetToRemove = (permanent: boolean) => {
        setRemoveDialogOpen(true);
        if (userHasRight('management')) setPermanent(permanent);
    };

    const onCloseRemove = () => {
        setRemoveDialogOpen(false);
        setPermanent(false);
    };

    return (
        <StopPropagationWrapper>
            <AssetManagementMenuItemsWrapper buttonType={buttonType} open={Boolean(anchorEl)} anchorEl={anchorEl} waiting={waiting} onSetAnchorEl={setAnchorEl}>
                <MediaManagementAssetMenuItemsCopyApiUrl asset={asset} onClose={() => setAnchorEl(undefined)} />
                <MediaManagementAssetMenuItemsDownload asset={asset} onClose={() => setAnchorEl(undefined)} onSetWaiting={setWaiting} />
                <AssetManagementAssetMenuItemsSetAvailable asset={asset} onClose={() => setAnchorEl(undefined)} onSetWaiting={setWaiting} />
                <AssetManagementAssetMenuItemsSetToDraft asset={asset} onClose={() => setAnchorEl(undefined)} onSetToDraftDialogOpen={setToDraftDialogOpen} />
                <AssetManagementAssetMenuItemsRestoreToDraft asset={asset} onClose={() => setAnchorEl(undefined)} onSetWaiting={setWaiting} />
                <AssetManagementAssetMenuItemsRemoveFromCollection asset={asset} onClose={() => setAnchorEl(undefined)} onSetWaiting={setWaiting} />
                <AssetManagementAssetMenuItemsMoveToBin asset={asset} onClose={() => setAnchorEl(undefined)} onSetAssetToRemove={onSetAssetToRemove} />
                <AssetManagementAssetMenuItemsDeletePermanently asset={asset} onClose={() => setAnchorEl(undefined)} onSetAssetToRemove={onSetAssetToRemove} />
            </AssetManagementMenuItemsWrapper>
            {removeDialogOpen && <AssetManagementRemoveAssetDialog asset={asset} permanent={permanent} onClose={onCloseRemove} />}
            {toDraftDialogOpen && (
                <AssetManagementSetToDraftDialog
                    asset={asset}
                    onClose={() => setAnchorEl(undefined)}
                    onSetWaiting={setWaiting}
                    onSetDialogOpen={setToDraftDialogOpen}
                />
            )}
        </StopPropagationWrapper>
    );
};

export default MediaManagementAssetMenu;
